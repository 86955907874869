<template>
  <ContainerHub class="hub-profile">
    <!-- Header Slot -->
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :description="description"
        :email="email"
      />
    </template>

    <!-- Body Slot -->
    <template slot="body">
      <TheProfile />
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'
import ContainerHub from '@containers/ContainerHub'
import TheProfile from '@blocks/profile/TheProfile'
import HubHeader from './HubHeader'

export default {
  name: 'HubProfile',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail'
    })
  },
  components: {
    HubHeader,
    ContainerHub,
    TheProfile
  }
}
</script>

<style lang="scss">
  .fieldset {
    // margin-top: 2rem;
    // margin-bottom: 1.5rem;
  }
</style>
