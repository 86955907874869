<template>
  <TheProfileFieldset class="fieldset--newsletter" :title="message.title">

    <div class="fieldset__child">
      <p v-if="message.description">{{ message.description }}</p>
      <TheProfileFieldCheckbox
        :name="'pesticide'"
        :error="errors.first('pesticide')"
        :label="message.fields.pesticide.label"
        v-model="pesticide"
        v-validate=""
      />
    </div>

  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldCheckbox from './TheProfileFieldCheckbox'

export default {
  name: 'TheProfileFieldsetNewsletter',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldCheckbox
  },
  computed: {
    pesticide: {
      get () { return this.state.pesticide },
      set (value) {
        this.updateSubscriptionsField({ key: 'pesticide', value })
      }
    },
    state () {
      return this.$store.state.profile.subscriptions
    },
    message () {
      return this.$t('message.profile.subscriptions.newsletter')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updateSubscriptionsField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--newsletter{

    // Paragraph

    p{
      @include media("small-medium"){
        font-size: 1.4rem;
      }
      @include media("large"){
        font-size: 1.4rem;
      }
    }

    // Fieldset Child

    .fieldset__child{
      &:nth-of-type(1){
        @include media("small-medium"){
          width: 100%;
        }
      }
    }
  }
</style>
