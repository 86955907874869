<template>
  <div class="profile-select">
    <label :for="name" :class="labelClasses">{{ label }}</label>
    <div class="profile-select__select">
      <select
        :id="name"
        :name="name"
        :type="type"
        :class="selectClasses"
        :disabled="disabled"
        :value="value"
        @change="handleUpdateValue">
        <option
          :value="option.toLowerCase()"
          :key="option"
          v-for="option in options">
          {{ option }}
        </option>
      </select>
    </div>
    <span class="error">{{ error || '&nbsp;' }}</span>
  </div>
</template>

<script>
export default {
  name: 'TheProfileSelect',
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    error: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    labelClasses () {
      return [
        { 'is-required': this.required }
      ]
    },
    selectClasses () {
      return [
        { 'has-error': this.errors.has(this.name) }
      ]
    }
  },
  mounted () {
    if (this.value === '') {
      this.$emit('input', 'none')
    }
  },
  methods: {
    handleUpdateValue (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss">
  .profile-select{

    // Label

    label{
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      font-weight: font-weights("normal");

      &.is-required {
        &::after {
          content: "*";
          display: inline-block;
          color: colors("error");
          font-size: 1.4rem;
          transform: translateY(-3px);
          margin-left: 0.2rem;
        }
      }
    }

    // Select

    &__select{
      @include custom-select;
      margin-bottom: 0;

      select{
        background-color: white;
        font-size: 1.4rem;
        line-height: 1.2;
        border: 1px solid darken(white, 20%);

        &:disabled {
          background-color: shade(colors(foreground), 10%)
        }

        &.has-error {
          background-color: colors("error");
        }

        &[aria-invalid=true] {
          border: 1px solid colors("error");
        }
      }
    }
  }
</style>
