
<template>
  <TheProfileFieldset class="fieldset--company" :title="message.title" :admin="true">

    <!-- Company -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'company'"
        :required="true"
        :disabled="true"
        :error="errors.first('company')"
        :label="message.fields.company.label"
        v-model="company"
        v-validate="'required'"
      />
    </div>

    <!-- Department -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'department'"
        :error="errors.first('department')"
        :label="message.fields.department.label"
        v-model="department"
        v-validate=""
      />
    </div>

    <!-- Function -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'job'"
        :disabled="true"
        :error="errors.first('job')"
        :label="message.fields.job.label"
        v-model="job"
        v-validate=""
      />
    </div>

  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'

export default {
  name: 'TheProfileFieldsetCompany',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput
  },
  computed: {
    company: {
      get () { return this.state.company },
      set (value) {
        this.updatePersonalField({ key: 'company', value })
      }
    },
    department: {
      get () { return this.state.department },
      set (value) {
        this.updatePersonalField({ key: 'department', value })
      }
    },
    job: {
      get () { return this.state.job },
      set (value) {
        this.updatePersonalField({ key: 'job', value })
      }
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.company')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--company{
    .fieldset__child{
    }
  }
</style>
