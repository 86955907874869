<template>
  <TheProfileFieldset class="fieldset--notifications" :title="message.title">

    <div class="fieldset__child">
      <TheProfileFieldSelect
        :name="'frequency'"
        :error="errors.first('frequency')"
        :options="message.fields.frequency.options"
        :label="message.fields.frequency.label"
        v-model="frequency"
        v-validate=""
      />
    </div>

    <div class="fieldset__child">
      <BaseTitle :tag="'h4'">{{ message.documents.title }}</BaseTitle>
      <p v-if="message.documents.description">{{ message.documents.description }}</p>
      <TheCategories :categories="categories" :context="'profile'" />
    </div>

    <div class="fieldset__child">
      <BaseTitle :tag="'h4'">{{ message.events.title }}</BaseTitle>
      <p v-if="message.events.description">{{ message.events.description }}</p>
      <TheProfileFieldCheckbox
        :name="'croplifeEvents'"
        :error="errors.first('croplifeEvents')"
        :label="message.fields.croplifeEvents.label"
        v-model="croplifeEvents"
        v-validate=""
      />
      <TheProfileFieldCheckbox
        :name="'externalEvents'"
        :error="errors.first('externalEvents')"
        :label="message.fields.externalEvents.label"
        v-model="externalEvents"
        v-validate=""
      />
    </div>

  </TheProfileFieldset>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldSelect from './TheProfileFieldSelect'
import TheProfileFieldCheckbox from './TheProfileFieldCheckbox'
import TheCategories from '@blocks/categories/TheCategories'

import BaseTitle from '@base/BaseTitle'
export default {
  name: 'TheProfileFieldsetNotifications',
  inject: ['$validator'],
  components: {
    BaseTitle,
    TheProfileFieldset,
    TheProfileFieldCheckbox,
    TheProfileFieldSelect,
    TheCategories
  },
  computed: {
    ...mapGetters('profile', {
      categories: 'getCategories'
    }),
    frequency: {
      get () { return this.state.frequency },
      set (value) {
        this.updateSubscriptionsField({ key: 'frequency', value })
      }
    },
    croplifeEvents: {
      get () { return this.state.croplifeEvents },
      set (value) {
        this.updateSubscriptionsField({ key: 'croplifeEvents', value })
      }
    },
    externalEvents: {
      get () { return this.state.externalEvents },
      set (value) {
        this.updateSubscriptionsField({ key: 'externalEvents', value })
      }
    },
    state () {
      return this.$store.state.profile.subscriptions
    },
    message () {
      return this.$t('message.profile.subscriptions.notifications')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updateSubscriptionsField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--notifications{

    // Paragraph

    p{
      @include media("small-medium"){
        font-size: 1.4rem;
      }
      @include media("large"){
        font-size: 1.4rem;
      }
    }

    // Title

    .title{
      text-transform: initial;
      font-weight: font-weights("bold");
      font-size: 1.8rem;
      display:block;
      border-bottom: 1px solid tint(colors("font"), 70%);
      padding-bottom: layout("spacing")*0.4;
      margin-bottom: layout("spacing")*0.4;
    }

    // Fieldset Child

    .fieldset__child{
      &:nth-of-type(1){
        @include media("small-medium"){
          width: 100%;
        }
      }
      &:nth-of-type(2),
      &:nth-of-type(3){
        width: 100%;
      }
      &:nth-of-type(3){
        .error{
          display: none;
        }
      }
    }

    .categories-item{
      @include media("small-medium"){
        width: 100%;
      }
      @include media("large-only"){
        width: calc((100% - #{layout("spacing")*2}) / 2);
      }
      @include media("xlarge"){
        width: calc((100% - #{layout("spacing")*4}) / 4);
      }
    }
  }
</style>
