<template>
  <TheProfileFieldset class="fieldset--assistant" :title="message.title">

    <!-- Name -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'assistantName'"
        :error="errors.first('assistantName')"
        :label="message.fields.name.label"
        v-model="name"
        v-validate=""
      />
    </div>

    <!-- Email -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'assistantEmail'"
        :error="errors.first('assistantEmail')"
        :label="message.fields.email.label"
        v-model="email"
        v-validate="'email'"
      />
    </div>

    <!-- Phone -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'assistantPhone'"
        :error="errors.first('assistantPhone')"
        :label="message.fields.phone.label"
        v-model="phone"
        v-validate=""
      />
    </div>

    <!-- Fax -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'assistantFax'"
        :error="errors.first('assistantFax')"
        :label="message.fields.fax.label"
        v-model="fax"
        v-validate=""
      />
    </div>
  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'

export default {
  name: 'TheProfileFieldsetAssistant',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput
  },
  computed: {
    name: {
      get () { return this.state.assistant.name },
      set (value) {
        this.updatePersonalNestedField({ key: 'assistant', nestedKey: 'name', value })
      }
    },
    email: {
      get () { return this.state.assistant.email },
      set (value) {
        this.updatePersonalNestedField({ key: 'assistant', nestedKey: 'email', value })
      }
    },
    phone: {
      get () { return this.state.assistant.phone },
      set (value) {
        this.updatePersonalNestedField({ key: 'assistant', nestedKey: 'phone', value })
      }
    },
    fax: {
      get () { return this.state.assistant.fax },
      set (value) {
        this.updatePersonalNestedField({ key: 'assistant', nestedKey: 'fax', value })
      }
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.assistant')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField',
      'updatePersonalNestedField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--assistant{
    .fieldset__child{
    }
  }
</style>
