<template>
  <form class="profile-personal" ref="form" @submit.prevent="handleSubmit($event)" novalidate>
    <TheProfileFieldsetNotifications />
    <TheProfileFieldsetNewsletter />

    <button class="profile__submit" type="submit" >
      {{$t(`message.common.buttons.updatePersonalDetails`)}}
      <BaseIcon type="reload" />
    </button>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchPostForm } from '@modules/api'
import BaseIcon from '@base/BaseIcon'
import TheProfileFieldsetNotifications from './TheProfileFieldsetNotifications'
import TheProfileFieldsetNewsletter from './TheProfileFieldsetNewsletter'

export default {
  name: 'TheProfileSubscriptions',
  components: {
    BaseIcon,
    TheProfileFieldsetNotifications,
    TheProfileFieldsetNewsletter
  },
  methods: {
    ...mapActions({
      fetchGetPage: 'page/fetchGetPage'
    }),
    handleSubmit (e) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const formData = new FormData(e.target)
          // for (var pair of formData.entries()) {
          //   console.log(pair[0] + ', ' + pair[1])
          // }
          fetchPostForm('profile-subscriptions', formData).then(() => {
            this.fetchGetPage({ service: 'profile' })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .profile-personal{

  }
</style>
