<template>
  <div class="container-collapser" :class="customClass" v-if="title">
    <div class="container-collapser__header" @click="handleExpand" >
      <h2 class="container-collapser__header__title">{{title}}</h2>
      <div class="container-collapser__header__icon">
      </div>
    </div>

    <div class="custom-content" v-show="isActive">
      <slot name="custom-content" :content="{content: content}"></slot>
    </div>
  </div>

  <div class="container-collapser" :class="customClass" v-else>
      <slot name="custom-content"></slot>
  </div>

</template>

<script>
export default {
  name: 'ContainerCollapser',
  data () {
    return {
      isActive: this.expand
    }
  },
  props: {
    title: {
      type: String,
      required: false
    },
    content: {
      type: Object,
      required: false
    },
    expand: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    customClass () {
      return this.isActive ? 'is-expanded' : 'is-collapsed'
    }
  },
  methods: {
    handleExpand () {
      this.isActive = !this.isActive
    }
  }

}
</script>

<style lang="scss">

.container-collapser {
  // header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid colors(foreground);

    .is-expanded & {
      border-bottom: 1px solid transparent
    }

    &__title {
      @media print {
        line-height: 2.5rem;
      }
      margin: 0;
      padding: 0;
      font-size: 2.4rem;
      line-height: 1.2rem;
      font-weight: font-weights(normal);
    }

    &__icon {
      @media print {
        display: none !important
      }
      width: 2.5rem;
      height: 2.5rem;
      position: relative;

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%, -50%);
        background-color: colors(primary);
        height: 0.3rem;
        width: 2.1rem;
        border-radius: .4rem;
      }
      &::after {
        transform: translate(-50%, -50%) rotate(90deg)
      }
      .is-expanded & {
        &::after {
          display: none;
        }
      }
    }

  }

}

</style>
