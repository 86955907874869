<template>
  <TheProfileFieldset class="fieldset--general" :title="message.title">

    <div class="fieldset__child">
      <TheProfileFieldSelect
        :name="'title'"
        :required="true"
        :error="errors.first('title')"
        :options="message.fields.title.options"
        :label="message.fields.title.label"
        v-model="title"
        v-validate="'required'"
      />
    </div>

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'lastname'"
        :required="true"
        :error="errors.first('lastname')"
        :label="message.fields.lastname.label"
        v-model="lastname"
        v-validate="'required'"
      />
    </div>

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'firstname'"
        :required="true"
        :error="errors.first('firstname')"
        :label="message.fields.firstname.label"
        v-model="firstname"
        v-validate="'required'"
      />
    </div>

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'middlename'"
        :label="message.fields.middlename.label"
        :error="errors.first('middlename')"
        v-model="middlename"
      />
    </div>

    <div class="fieldset__child">
      <TheProfileFieldFile
        :name="'picture'"
        :label="message.fields.picture.label"
        :error="errors.first('picture')"
        v-model="picture"
      />
    </div>
  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'
import TheProfileFieldSelect from './TheProfileFieldSelect'
import TheProfileFieldFile from './TheProfileFieldFile'

export default {
  name: 'TheProfileFieldsetGeneral',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput,
    TheProfileFieldSelect,
    TheProfileFieldFile
  },
  computed: {
    title: {
      get () { return this.state.title },
      set (value) {
        this.updatePersonalField({ key: 'title', value })
      }
    },
    lastname: {
      get () { return this.state.lastname },
      set (value) {
        this.updatePersonalField({ key: 'lastname', value })
      }
    },
    firstname: {
      get () { return this.state.firstname },
      set (value) {
        this.updatePersonalField({ key: 'firstname', value })
      }
    },
    middlename: {
      get () { return this.state.middlename },
      set (value) {
        this.updatePersonalField({ key: 'middlename', value })
      }
    },
    picture: {
      get () { return this.state.picture },
      set (value) {
        this.updatePersonalField({ key: 'picture', value })
      }
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.general')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--general{
    .fieldset__child{
      &:last-of-type{
        @include media("small-medium"){
          width: 100%;
        }
        @include media("large"){
          width: 50%;
        }
      }
    }
  }
</style>
