<template>
  <div class="profile-input">
    <label :for="name" :class="labelClasses">{{ label }}</label>
    <input
      :id="name"
      :name="name"
      :type="type"
      :class="inputClasses"
      :disabled="disabled"
      :value="value"
      @input="handleUpdateValue"
      @change="handleUpdateValue"
    />
    <span class="error">{{ error || '&nbsp;' }}</span>
  </div>
</template>

<script>
export default {
  name: 'TheProfileInput',
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    labelClasses () {
      return [
        { 'is-required': this.required }
      ]
    },
    inputClasses () {
      return [
        { 'has-error': this.errors.has(this.name) }
      ]
    }
  },
  methods: {
    handleUpdateValue (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss">
  .profile-input{
    label{
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      font-weight: font-weights("normal");

      &.is-required {
        &::after {
          content: "*";
          display: inline-block;
          color: colors("error");
          font-size: 1.4rem;
          transform: translateY(-3px);
          margin-left: 0.2rem;
        }
      }
    }

    input{
      border-radius: .4rem;
      font-size: 1.4rem;
      margin-bottom: 0;
      display: inline-block;
      line-height: 1;

      &:disabled {
        background-color: shade(colors(foreground), 10%)
      }

      &.has-error {
        background-color: colors("error");
      }

      &[aria-invalid=true] {
        border:1px solid colors("error");
      }
    }
  }
</style>
