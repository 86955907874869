<template>
  <TheProfileFieldset class="fieldset--mailing" :title="message.title">

    <!-- Address -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'mailingAddress'"
        :required="true"
        :error="errors.first('mailingAddress')"
        :label="message.fields.streetname.label"
        v-model="streetname"
        v-validate="'required'"
      />
    </div>

    <!-- Number -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'mailingNumber'"
        :required="true"
        :error="errors.first('mailingNumber')"
        :label="message.fields.number.label"
        v-model="number"
        v-validate="'required'"
      />
    </div>

    <!-- Box -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'mailingBox'"
        :error="errors.first('mailingBox')"
        :label="message.fields.box.label"
        v-model="box"
        v-validate=""
      />
    </div>

    <!-- Zip -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'mailingZip'"
        :required="true"
        :error="errors.first('mailingZip')"
        :label="message.fields.zip.label"
        v-model="zip"
        v-validate="'required'"
      />
    </div>

    <!-- City -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'mailingCity'"
        :required="true"
        :error="errors.first('mailingCity')"
        :label="message.fields.city.label"
        v-model="city"
        v-validate="'required'"
      />
    </div>

    <!-- Twitter -->

    <div class="fieldset__child">
    <TheProfileFieldSelect
        :name="'mailingCountry'"
        :required="true"
        :error="errors.first('mailingCountry')"
        :options="$t('message.countries')"
        :label="message.fields.country.label"
        v-model="country"
        v-validate="'required'"
      />
    </div>
  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'
import TheProfileFieldSelect from './TheProfileFieldSelect'

export default {
  name: 'TheProfileFieldsetMailing',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput,
    TheProfileFieldSelect
  },
  computed: {
    streetname: {
      get () { return this.state.mailing.streetname },
      set (value) {
        this.updatePersonalNestedField({ key: 'mailing', nestedKey: 'streetname', value })
      }
    },
    number: {
      get () { return this.state.mailing.number },
      set (value) {
        this.updatePersonalNestedField({ key: 'mailing', nestedKey: 'number', value })
      }
    },
    box: {
      get () { return this.state.mailing.box },
      set (value) {
        this.updatePersonalNestedField({ key: 'mailing', nestedKey: 'box', value })
      }
    },
    zip: {
      get () { return this.state.mailing.zip },
      set (value) {
        this.updatePersonalNestedField({ key: 'mailing', nestedKey: 'zip', value })
      }
    },
    city: {
      get () { return this.state.mailing.city },
      set (value) {
        this.updatePersonalNestedField({ key: 'mailing', nestedKey: 'city', value })
      }
    },
    country: {
      get () { return this.state.mailing.country },
      set (value) {
        this.updatePersonalNestedField({ key: 'mailing', nestedKey: 'country', value })
      }
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.mailing')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField',
      'updatePersonalNestedField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--mailing{
    .fieldset__child{
      &:nth-of-type(2) {
        @include media('small') {
          margin-left: 0;
        }
        margin-left: 1rem;
      }
      &:nth-of-type(2), &:nth-of-type(3) {
        @include media('large') {
          width: calc((100% - #{layout("spacing") * 2}) / 4);
        }
        @include media('medium') {
          width: calc((100% - #{layout("spacing") }) / 2);
          margin-left: 0;
        }
      }

    }
  }
</style>
