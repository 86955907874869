<template>
  <TheProfileFieldset class="fieldset--contact" :title="message.title" :admin="true">

    <!-- Phone -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'phone'"
        :required="true"
        :error="errors.first('phone')"
        :label="message.fields.phone.label"
        v-model="phone"
        v-validate="'required'"
      />
    </div>

    <!-- Fax -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'fax'"
        :error="errors.first('fax')"
        :label="message.fields.fax.label"
        v-model="fax"
        v-validate=""
      />
    </div>

    <!-- Mobile Phone -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'mobilePhone'"
        :error="errors.first('mobilePhone')"
        :label="message.fields.mobilePhone.label"
        v-model="mobilePhone"
        v-validate=""
      />
    </div>

    <!-- Email -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'email'"
        :error="errors.first('email')"
        :required="true"
        :disabled="true"
        :label="message.fields.email.label"
        v-model="email"
        v-validate="'required|email'"
      />
    </div>

    <!-- Facebook -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'facebook'"
        :error="errors.first('facebook')"
        :label="message.fields.facebook.label"
        v-model="facebook"
        v-validate=""
      />
    </div>

    <!-- Twitter -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'twitter'"
        :error="errors.first('twitter')"
        :label="message.fields.twitter.label"
        v-model="twitter"
        v-validate=""
      />
    </div>

    <!-- Linkedin -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'linkedin'"
        :error="errors.first('linkedin')"
        :label="message.fields.linkedin.label"
        v-model="linkedin"
        v-validate=""
      />
    </div>

  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'

export default {
  name: 'TheProfileFieldsetContact',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput
  },
  computed: {
    phone: {
      get () { return this.state.phone },
      set (value) {
        this.updatePersonalField({ key: 'phone', value })
      }
    },
    fax: {
      get () { return this.state.fax },
      set (value) {
        this.updatePersonalField({ key: 'fax', value })
      }
    },
    mobilePhone: {
      get () { return this.state.mobilePhone },
      set (value) {
        this.updatePersonalField({ key: 'mobilePhone', value })
      }
    },
    email: {
      get () { return this.state.email },
      set (value) {
        this.updatePersonalField({ key: 'email', value })
      }
    },
    facebook: {
      get () { return this.state.facebook },
      set (value) {
        this.updatePersonalField({ key: 'facebook', value })
      }
    },
    twitter: {
      get () { return this.state.twitter },
      set (value) {
        this.updatePersonalField({ key: 'twitter', value })
      }
    },
    linkedin: {
      get () { return this.state.linkedin },
      set (value) {
        this.updatePersonalField({ key: 'linkedin', value })
      }
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.contact')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--contact{
    .fieldset__child{
    }
  }
</style>
