<template>
  <div class="profile-checkbox">
    <label :for="name" :class="labelClasses">
      <span class="profile-checkbox__square"></span>
      <input
        :id="name"
        :name="name"
        :type="'checkbox'"
        :class="inputClasses"
        :disabled="disabled"
        :checked="value"
        @input="handleUpdateValue"
        @change="handleUpdateValue"
      />
      <div class="profile-checkbox__label">
        <span>{{ label }}</span>
      </div>
    </label>
    <!-- <span class="error">{{ error || '&nbsp;' }}</span> -->
  </div>
</template>

<script>
export default {
  name: 'TheProfileCheckbox',
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    labelClasses () {
      return [
        { 'is-selected': this.value },
        { 'is-required': this.required }
      ]
    },
    inputClasses () {
      return [
        'profile-checkbox__input',
        { 'has-error': this.errors.has(this.name) }
      ]
    }
  },
  methods: {
    handleUpdateValue (e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>

<style lang="scss">
  .profile-checkbox{

    // Label

    label{
      font-weight: font-weights("medium");
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
    }

    // Square

    &__square{
      @include size(1.6rem);
      @include icon(icons("check"));

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      background-color: white;
      border: 1px solid shade(colors("foreground"), 20%);
      flex: 0 0 auto;

      &::before{
        @include add-transition;
        color: colors("primary");
        font-size: 0.9rem;
        opacity: 0;
      }

      .is-selected &{
        &::before{
          opacity: 1;
        }
      }

      .is-hollow &{
        border: 1px solid white;
        background-color: transparent;

        &::before{
          color: white;
        }
      }
    }

    // Input

    &__input{
      @include hide-visually;
      flex: 1 1 auto;
      order: 1;
    }

    &__label{
      @include add-hover-underline(left, colors("font"), 1px);
      order: 2;
      flex: 0 1 auto;
      margin-left: layout("spacing")/2;
    }

    // label{
    //   font-size: 1.6rem;
    //   margin-bottom: 0;
    //   display: inline-block;
    //   font-weight: font-weights("normal");

    //   &.is-required {
    //     &::after {
    //       content: "*";
    //       display: inline-block;
    //       color: colors("error");
    //       font-size: 1.4rem;
    //       transform: translateY(-3px);
    //       margin-left: 0.2rem;
    //     }
    //   }
    // }

    // input{
    //   border-radius: .4rem;
    //   font-size: 1.4rem;
    //   margin-bottom: 0;
    //   display: inline-block;
    //   line-height: 1;

    //   &:disabled {
    //     background-color: shade(colors(foreground), 10%)
    //   }

    //   &.has-error {
    //     background-color: colors("error");
    //   }

    //   &[aria-invalid=true] {
    //     border:1px solid colors("error");
    //   }
    // }
  }
</style>
