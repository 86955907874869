<template>
  <div class="profile-view">
    <HubProfile name="profile" v-if="loaded" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import updateBreadcrumb from '@mixins/updateBreadcrumb'
import HubProfile from '@blocks/hubs/HubProfile'

export default {
  name: 'ProfileView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubProfile
  },
  data () {
    return {
      loaded: false
    }
  },
  created () {
    this.fetchGetPage({ service: 'profile' }).then(() => {
      this.loaded = true
    })
  },
  methods: {
    ...mapActions({
      fetchGetPage: 'page/fetchGetPage'
    })
  }
}
</script>

<style lang="scss">
.profile-view{

}
</style>
