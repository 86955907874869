<template>
  <div class="profile-file">
    <label :for="name">{{ label }}</label>
    <div class="profile-file__inner">
      <input
        :type="'file'"
        :id="name"
        class="hide-visually"
        accept="image/*"
        @change="handleUpdateValue">

      <label class="profile-file__image" :for="name">
        <img :src="avatar" alt="" >
      </label>

      <label class="profile-file__button" :for="name">
          {{ $t(`message.common.buttons.changePicture`) }}
          <BaseIcon type="picture" />
      </label>

      <small>{{$t(`message.common.buttons.or`)}}</small>

      <span class="profile-file__delete" @click="handleDeletePicture">
        {{ $t(`message.common.buttons.delete`) }}
      </span>
    </div>
    <span class="error">{{ error || '&nbsp;' }}</span>
  </div>
</template>

<script>
import defaultPicture from './_images/default-avatar.png'
import BaseIcon from '@base/BaseIcon'

export default {
  name: 'TheProfileFile',
  $_veeValidate: {
    value () {
      return this.value
    },
    name () {
      return this.name
    }
  },
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    BaseIcon
  },
  data () {
    return {
      avatar: '',
      defaultPicture: defaultPicture
    }
  },
  watch: {
    value () {
      this.setAvatar()
    }
  },
  created () {
    this.setAvatar()
  },
  methods: {
    setAvatar () {
      this.avatar = this.value || this.defaultPicture
    },
    handleUpdateValue (e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (event) => {
        this.avatar = event.target.result
        this.$emit('input', this.avatar)
      }
      reader.readAsDataURL(file)
    },
    handleDeletePicture () {
      this.$emit('input', '')
    }
  }
}
</script>

<style lang="scss">
  .profile-file{

    // Label

    > label{
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      display: inline-block;
      font-weight: font-weights("normal");

      &.is-required {
        &::after {
          content: "*";
          display: inline-block;
          color: colors('error');
          font-size: 1.4rem;
          transform: translateY(-3px);
          margin-left: 0.2rem;
        }
      }
    }

    // Inner

    &__inner{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid colors(border);
      padding: 1rem;
      background-color: colors("background");
      border-radius: .4rem;
    }

    // Image

    &__image{
      @include media("large"){
        cursor: pointer;
      }

      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.3rem;
      height: 9.3rem;
      margin-bottom: 0;
      overflow: hidden;
      flex: 0 1 auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    // Button

    &__button{
      @include background-color(colors(primary));
      @include media("large"){
        cursor: pointer;
      }

      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius:.4rem;
      padding: 0.5rem 1rem;
      margin: 0 1rem 0 0;
      color: colors("background");
      font-size: 1.4rem;
      flex: 0 0 auto;
      font-weight: font-weights(normal);

      .icon::before {
        font-size: 1.4rem;
        margin-left: 0.5rem;
      }
    }

    // Delete

    &__delete {
       @include media("large"){
        cursor: pointer;
      }

      font-size: 1.4rem;
      flex: 0 0 auto;
      color: colors(primary);
    }

    small {
      color: colors(tertiary);
      font-size: 1.4rem;
      margin-right: 1rem;
    }
  }
</style>
