<template>
  <div class="profile">
      <!-- Personal detail -->
      <ContainerCollapser :title="$t('message.profile.personal.title')" :expand="true">
        <template slot="custom-content">
          <TheProfilePersonal />
        </template>
      </ContainerCollapser>

      <!-- Subscriptions detail -->
      <ContainerCollapser :title="$t('message.profile.subscriptions.title')" :expand="true">
        <template slot="custom-content">
          <TheProfileSubscriptions />
        </template>
      </ContainerCollapser>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContainerCollapser from '@containers/ContainerCollapser'
import TheProfilePersonal from './TheProfilePersonal'
import TheProfileSubscriptions from './TheProfileSubscriptions'

export default {
  name: 'TheProfile',
  components: {
    ContainerCollapser,
    TheProfilePersonal,
    TheProfileSubscriptions
  },
  computed: {
    ...mapState('profile', [
      'profile',
      'personal'
    ])
  }
}
</script>

<style lang='scss'>
  .profile{
    @media print {
      .custom-content{
        display: block !important;
      }
      .fieldset{
        background-color: transparent;
        &__body{
          > * {
            width: calc( 50% - 1rem);
          }
        }
      }
    }
    .container-collapser{
      padding-bottom: layout("spacing");

      &__header{
        margin-bottom: layout("spacing")/2;
      }
    }

    &__submit {
      @include background-color(colors(primary));
      @include media("large"){
        cursor: pointer;
      }

      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      border-radius:.4rem;
      padding: 0.5rem 1rem;
      margin: layout("spacing") 1rem 1rem 0;
      color: colors("background");
      font-size: 1.4rem;
      font-weight: font-weights(normal);

      .icon::before {
        font-size: 1.4rem;
        margin-left: 0.5rem;
      }
    }
  }
</style>
