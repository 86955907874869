<template>
  <TheProfileFieldset class="fieldset--special" :title="message.title">

    <!-- Dietary -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'dietary'"
        :error="errors.first('dietary')"
        :label="message.fields.dietary.label"
        v-model="dietary"
        v-validate=""
      />
    </div>
  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'

export default {
  name: 'TheProfileFieldsetSpecial',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput
  },
  computed: {
    dietary: {
      get () { return this.state.dietary },
      set (value) {
        this.updatePersonalField({ key: 'dietary', value })
      }
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.special')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField',
      'updatePersonalNestedField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--special{
    .fieldset__child{
    }
  }
</style>
