<template>
  <TheProfileFieldset class="fieldset--street" :title="message.title">
    <div class="fieldset__child">
      <TheProfileFieldCheckbox
        :name="'sameAddress'"
        :error="errors.first('sameAddress')"
        :label="message.fields.sameAddress.label"
        v-model="sameAddress"
        v-validate=""
      />
    </div>

    <!-- Address -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'streetAddress'"
        :disabled="disabled"
        :error="errors.first('streetAddress')"
        :label="message.fields.streetname.label"
        v-model="streetname"
        v-validate=""
      />
    </div>

    <!-- Number -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'streetNumber'"
        :disabled="disabled"
        :error="errors.first('streetNumber')"
        :label="message.fields.number.label"
        v-model="number"
        v-validate=""
      />
    </div>

    <!-- Box -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'streetBox'"
        :disabled="disabled"
        :error="errors.first('streetBox')"
        :label="message.fields.box.label"
        v-model="box"
        v-validate=""
      />
    </div>

    <!-- Zip -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'streetZip'"
        :disabled="disabled"
        :error="errors.first('streetZip')"
        :label="message.fields.zip.label"
        v-model="zip"
        v-validate=""
      />
    </div>

    <!-- City -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'streetCity'"
        :disabled="disabled"
        :error="errors.first('streetCity')"
        :label="message.fields.city.label"
        v-model="city"
        v-validate=""
      />
    </div>

    <!-- Twitter -->

    <div class="fieldset__child">
      <TheProfileFieldSelect
        :name="'streetCountry'"
        :disabled="disabled"
        :error="errors.first('streetCountry')"
        :options="countries"
        :label="message.fields.country.label"
        v-model="country"
        v-validate=""
      />
    </div>
  </TheProfileFieldset>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'
import TheProfileFieldSelect from './TheProfileFieldSelect'
import TheProfileFieldCheckbox from './TheProfileFieldCheckbox'

export default {
  name: 'TheProfileFieldsetStreet',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput,
    TheProfileFieldCheckbox,
    TheProfileFieldSelect
  },
  computed: {
    ...mapState('profile', [
      'personal'
    ]),
    sameAddress: {
      get () { return this.state.street.sameAddress },
      set (value) {
        this.updatePersonalNestedField({ key: 'street', nestedKey: 'sameAddress', value })
      }
    },
    streetname: {
      get () { return this.state.street.streetname },
      set (value) {
        this.updatePersonalNestedField({ key: 'street', nestedKey: 'streetname', value })
      }
    },
    number: {
      get () { return this.state.street.number },
      set (value) {
        this.updatePersonalNestedField({ key: 'street', nestedKey: 'number', value })
      }
    },
    box: {
      get () { return this.state.street.box },
      set (value) {
        this.updatePersonalNestedField({ key: 'street', nestedKey: 'box', value })
      }
    },
    zip: {
      get () { return this.state.street.zip },
      set (value) {
        this.updatePersonalNestedField({ key: 'street', nestedKey: 'zip', value })
      }
    },
    city: {
      get () { return this.state.street.city },
      set (value) {
        this.updatePersonalNestedField({ key: 'street', nestedKey: 'city', value })
      }
    },
    country: {
      get () { return this.state.street.country },
      set (value) {
        this.updatePersonalNestedField({ key: 'street', nestedKey: 'country', value })
      }
    },
    countries () {
      return this.$t('message.countries')
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.street')
    },
    disabled () {
      return this.sameAddress
    }
  },
  watch: {
    sameAddress () {
      this.updateStreetAddress()
    }
  },
  created () {
    if (this.sameAddress) this.updateStreetAddress()
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField',
      'updatePersonalNestedField',
      'updateStreetAddress'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--street{
    .fieldset__child{
      &:first-of-type{
        width: 100%;
      }
      &:nth-of-type(3) {
        @include media('small') {
          margin-left: 0;
        }
        margin-left: 1rem;
      }
      &:nth-of-type(3), &:nth-of-type(4) {
        @include media('large') {
          width: calc((100% - #{layout("spacing") * 2}) / 4);
        }

        @include media('medium') {
          width: calc((100% - #{layout("spacing") }) / 2);
          margin-left: 0;
        }
      }

    }
  }
</style>
