<template>
  <TheProfileFieldset class="fieldset--password" :title="message.title">

    <!-- Password -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'password'"
        :type="'password'"
        :error="errors.first('password')"
        :label="message.fields.password.label"
        v-model="password"
        v-validate="'min:1|max:16'"
        ref="password"
      />
    </div>

    <!-- Password Confirmation -->

    <div class="fieldset__child">
      <TheProfileFieldInput
        :name="'passwordConfirmation'"
        :type="'password'"
        :error="errors.first('passwordConfirmation')"
        :label="message.fields.passwordConfirmation.label"
        v-model="passwordConfirmation"
        v-validate="'confirmed:password'"
        data-vv-as="password"
      />
    </div>

  </TheProfileFieldset>
</template>

<script>
import { mapMutations } from 'vuex'
import TheProfileFieldset from './TheProfileFieldset'
import TheProfileFieldInput from './TheProfileFieldInput'

export default {
  name: 'TheProfileFieldsetPassword',
  inject: ['$validator'],
  components: {
    TheProfileFieldset,
    TheProfileFieldInput
  },
  computed: {
    password: {
      get () { return this.state.password },
      set (value) {
        this.updatePersonalField({ key: 'password', value })
      }
    },
    passwordConfirmation: {
      get () { return this.state.passwordConfirmation },
      set (value) {
        this.updatePersonalField({ key: 'passwordConfirmation', value })
      }
    },
    state () {
      return this.$store.state.profile.personal
    },
    message () {
      return this.$t('message.profile.personal.password')
    }
  },
  methods: {
    ...mapMutations('profile', [
      'updatePersonalField'
    ])
  }
}
</script>

<style lang="scss">
  .fieldset--password{
    .fieldset__child{
    }
  }
</style>
