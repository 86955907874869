<template>
  <form class="profile-personal" ref="form" @submit.prevent="handleSubmit($event)" novalidate>
    <TheProfileFieldsetGeneral />
    <TheProfileFieldsetCompany />
    <TheProfileFieldsetContact />
    <TheProfileFieldsetPassword />
    <TheProfileFieldsetMailing />
    <TheProfileFieldsetStreet />
    <TheProfileFieldsetAssistant />
    <TheProfileFieldsetSpecial />

    <button class="profile__submit" type="submit" >
      {{$t(`message.common.buttons.updatePersonalDetails`)}}
      <BaseIcon type="reload" />
    </button>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { fetchPostForm } from '@modules/api'
import BaseIcon from '@base/BaseIcon'
import TheProfileFieldsetGeneral from './TheProfileFieldsetGeneral'
import TheProfileFieldsetCompany from './TheProfileFieldsetCompany'
import TheProfileFieldsetContact from './TheProfileFieldsetContact'
import TheProfileFieldsetPassword from './TheProfileFieldsetPassword'
import TheProfileFieldsetMailing from './TheProfileFieldsetMailing'
import TheProfileFieldsetStreet from './TheProfileFieldsetStreet'
import TheProfileFieldsetAssistant from './TheProfileFieldsetAssistant'
import TheProfileFieldsetSpecial from './TheProfileFieldsetSpecial'

export default {
  name: 'TheProfilePersonal',
  components: {
    BaseIcon,
    TheProfileFieldsetGeneral,
    TheProfileFieldsetCompany,
    TheProfileFieldsetContact,
    TheProfileFieldsetPassword,
    TheProfileFieldsetMailing,
    TheProfileFieldsetStreet,
    TheProfileFieldsetAssistant,
    TheProfileFieldsetSpecial
  },
  methods: {
    ...mapActions({
      fetchGetPage: 'page/fetchGetPage'
    }),
    handleSubmit (e) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const formData = new FormData(e.target)
          const pictureFile = e.target[4].files[0]
          if (pictureFile && pictureFile.type.match(/image.*/)) {
            formData.append('picture', pictureFile)
          }
          // for (var pair of formData.entries()) {
          //   console.log(pair[0] + ', ' + pair[1])
          // }
          fetchPostForm('profile-personal', formData).then(() => {
            this.fetchGetPage({ service: 'profile' })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .profile-personal{

  }
</style>
