<template>
  <div class="fieldset">
    <div class="fieldset__header" v-if="title">
      <BaseTitle tag="h3">{{ title }}</BaseTitle>
    </div>
     <div class="fieldset__body">
       <slot></slot>
    </div>
    <BaseButton
      clear
      :icon="'chevron-right'"
      :icon-position="'left'"
      :color="'primary'"
      :url="$t('message.profile.admin')"
      v-if="admin">
        {{$t(`message.profile.adminContact`)}}
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@base/BaseButton'
import BaseTitle from '@base/BaseTitle'

export default {
  name: 'TheProfileFieldset',
  components: {
    BaseButton,
    BaseTitle
  },
  props: {
    title: {
      type: String,
      required: false
    },
    content: {
      type: Object,
      required: false
    },
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.fieldset {
  padding: 2rem;
  border-left: 6px solid colors(modules);
  border-radius: .4rem;
  background-color: colors("foreground");
  margin-bottom: layout("spacing");

  // Title

  .title{
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: font-weights("bold");
    font-size: 2rem;
  }

  // Header

  &__header {
    margin-bottom: 1rem;
  }

  // Body

  &__body{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  // Child

  &__child{
    @include media("small-medium"){
      width: 100%;
    }
    @include media("large"){
      width: calc((100% - #{layout("spacing")}) / 2);
    }
    flex: 0 1 auto;

    .error{
      color: colors("error");
      font-size: 1.2rem;
      display: block;
      line-height: 1.2;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    // label{
    //   font-size: 1.6rem;
    //   display: inline-block;
    //   margin-bottom: 0;
    //   font-weight: font-weights(normal);

    //   .is-checkbox & {
    //     margin-left: 1rem;
    //   }

    //   &.is-required {
    //     &::after {
    //       content: "*";
    //       display: inline-block;
    //       color: colors('error');
    //       font-size: 1.4rem;
    //       transform: translateY(-3px);
    //       margin-left: 0.2rem;
    //     }
    //   }
    // }
  }

  // &--mailingAddress {
  //   .fieldset__child{
  //     &--mailingaddressnumber {
  //       width: calc((100% - #{layout("spacing") * 4}) / 4);
  //     }
  //     &--mailingaddressbox {
  //       width: calc((100% - #{layout("spacing") * 4}) / 4);
  //       margin-right:0 !important;
  //     }
  //   }
  // }

  // &--streetAddress {
  //   .fieldset__child{
  //     &--sameasmailing {
  //       width: 100%
  //     }
  //     &--streetaddressnumber {
  //       width: calc((100% - #{layout("spacing") * 4}) / 4);
  //     }
  //     &--streetaddressbox {
  //       width: calc((100% - #{layout("spacing") * 4}) / 4);
  //       margin-right:0 !important;
  //     }
  //   }
  // }
}

</style>
